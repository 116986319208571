import { useRouter } from 'next/router'
import { useEffect, useLayoutEffect, useState, useMemo } from 'react'
import { atobID, btoaID, getURLParameter } from '@lib/utils/tools'
import { getProductVariant } from 'components/product/helpers'
import { getQuery } from '@lib/utils/cookies'
import { serializeObject } from '@lib/utils/tools'
import { Options, Product, ProductVariant } from '@commerce/types/product'

export function updateQuery(variant: ProductVariant) {
  const query: any = getQuery()
  query['variant'] = atobID(variant?.id)
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${serializeObject(query)}${location.hash}`
  )
}
export function removeVariantQuery() {
  let url = new URL(window.location.href)
  url.searchParams.delete('variant')
  window.history.replaceState({}, '', url.toString())
}

export function useVariant(
  product: Product,
  selectedOptions: Options,
  isUpdateQuery = true,
  removeVariantParam = false
) {
  /**
   * 初始值为产品的第一个
   * 根据 selectedOptions 得到当前 variant
   * 更新 variant
   * 更新 query
   */
  const router = useRouter()
  const [variant, setVariant] = useState({
    ...(product?.variants?.[0] || {}),
    productHandle: product?.slug,
  })

  useLayoutEffect(() => {
    const queryID = getURLParameter('variant', window.location.href)
    const variantId = btoaID(queryID)
    const selectVariant = product?.variants?.find(
      (variant: ProductVariant) => variant.id === variantId
    )
    if (selectVariant)
      setVariant({ ...selectVariant, productHandle: product?.slug })
  }, [])

  useEffect(() => {
    const v = getProductVariant(product, selectedOptions)

    if (v && router.isReady) {
      v.productHandle = product.slug
      setVariant({ ...v, productHandle: product?.slug })
      isUpdateQuery && updateQuery(v)
      removeVariantParam && removeVariantQuery()
    } else if (router.isReady) {
    }
  }, [selectedOptions, product])

  // const variant = useMemo(() => {
  //   const queryID = getURLParameter('variant', window.location.href)
  //   const variantId = btoaID(queryID)
  //   const selectVariant = product?.variants?.find(
  //     (variant: ProductVariant) => variant.id === variantId
  //   )
  //   if (selectVariant) {
  //     selectVariant.productHandle = product.slug
  //     return selectVariant
  //   }

  //   // const current = getProductVariant(product, selectedOptions)
  //   // if (current) {
  //   //   current.productHandle = product.slug
  //   //   return current
  //   // }
  //   return product.variants?.[0]
  // }, [selectedOptions, product])

  return variant
}
