import type { Options, Product } from '@commerce/types/product'
import { Dispatch, SetStateAction } from 'react'

export function getProductVariant(product: Product, opts: Options) {
  // 根据已选的 option 获取当前的 variant
  const variant = product?.variants?.find((variant) => {
    return Object.entries(opts).every(([key, value]) =>
      variant.options.find((option) => {
        if (
          option.__typename === 'MultipleChoiceOption' &&
          option.displayName.toLowerCase() === key.toLowerCase()
        ) {
          return option.values.find((v) => v.label.toLowerCase() === value)
        }
      })
    )
  })
  return variant
}

export function selectDefaultOptionFromProduct(
  product: Product,
  updater: Dispatch<SetStateAction<Options>>,
  selectedOptions: Options
) {
  const hasSameOpt = product.variants.some((variant) => {
    return variant.options.every(
      (o) =>
        selectedOptions?.hasOwnProperty(o.displayName) &&
        selectedOptions[o.displayName] === o.values[0].label.toLowerCase()
    )
  })
  // Selects the default option
  if (!hasSameOpt) {
    product.variants[0]?.options?.forEach((v) => {
      updater((choices) => {
        return {
          ...choices,
          [v.displayName.toLowerCase()]: v.values[0].label.toLowerCase(),
        }
      })
    })
  }
}
