/* eslint-disable react-hooks/exhaustive-deps */

import { useRouter } from 'next/router'
import { btoaID, getURLParameter } from '@lib/utils/tools'

import { useUI } from '@components/ui/context'
import { isObjEmpty } from '@components/helper'
import { useEffect, useState } from 'react'
import {
  Options,
  Product,
  ProductOption,
  ProductVariant,
} from '@commerce/types/product'

/**
 *
 * 由 ProductOption[] 到 key value 的形式
 * { color: 'white', length:'3ft' }
 */

export function optionsConstructor(options: ProductOption[]): Options {
  return options?.reduce((pre: any, cur: any) => {
    pre[cur?.displayName?.toLowerCase()] = cur.values?.[0]?.label?.toLowerCase()
    return pre
  }, {})
}

export function useInitializedOptionsState(product: Product, sku: string) {
  /**
   * 根据 query string 初始化 selectedOptions
   * 如果传入sku则根据sku获取variant，否则默认根据query的variant获取
   */

  const { selectedOptions, setSelectedOptions } = useUI()
  const router = useRouter()

  useEffect(() => {
    if (router.isReady) {
      const queryID = getURLParameter('variant', window.location.href)
      const variantId = btoaID(queryID)
      // console.log('variantId:', variantId)

      // 页面初始化读取 query 中的 variant, 如果没有找到对应 query 中 id 的 variant，默认选中第一个
      const selectVariant = product?.variants?.find((variant: ProductVariant) =>
        sku ? variant.sku === sku : variant.id === variantId
      )

      const hasSameOpt =
        product.variants.find((variant) => {
          return variant.options.every(
            (o) =>
              selectedOptions.hasOwnProperty(o.displayName) &&
              selectedOptions[o.displayName] === o.values[0].label.toLowerCase()
          )
        }) || product.variants?.[0]

      const variant = selectVariant || hasSameOpt

      // TODO: shopify storefront api 似乎并没有提到 displayName 这个字段，但是返回值却有
      // 更新当前 variant 对应的 options
      const update_options = optionsConstructor(variant?.options)
      if (!isObjEmpty(update_options)) {
        setSelectedOptions(update_options)
      }
    }
  }, [router.isReady, product])

  return [selectedOptions, setSelectedOptions]
}
